<template>
  <span class="bmd-form-group">
    <b-input-group>
      <b-input-group-prepend class="d-none d-sm-block">
        <b-input-group-text>
          <i :class="`bi bi-${icon} fs-3 pt-2`"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <select
        class="form-control setup-select"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
      >
        <option
          v-for="option in options"
          v-bind:value="option.value"
          v-bind:key="option.value"
          :disabled="option.value === null"
        >
          {{ option.text }}
        </option>
      </select>
      <i
        :id="name + '-help'"
        v-if="info"
        class="bi bi-question fs-3 text-info md-18 help-icon"
      ></i>
      <b-tooltip
        :target="name + '-help'"
        placement="top"
        triggers="hover blur"
        delay="100"
      >
        {{ info }}
      </b-tooltip>
    </b-input-group>
  </span>
</template>

<script>
export default {
  name: 'SelectInput',
  props: ['name', 'icon', 'info', 'options', 'value', 'required']
}
</script>

<style scoped>
.setup-select {
  cursor: pointer;
}
</style>
