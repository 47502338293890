<template>
  <div class="d-flex align-items-center">
    <i :class="`bi bi-${icon} fs-2 pt-1 me-3`"></i>
    <input
      type="text"
      class="form-control"
      :required="required"
      :placeholder="placeholder"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: ['name', 'icon', 'placeholder', 'value', 'required']
}
</script>

<style scoped></style>
