<template>
  <div class="off-canvas-sidebar">
    <div class="wrapper wrapper-full-page">
      <div class="page-header login-page header-filter">
        <b-container class="pl-0 pl-sm-15 pr-0 pr-sm-15 pt-10">
          <b-row>
            <span
              class="spinner-border spinner-border-lg center-spinner"
              v-if="loading"
            ></span>
            <b-col
              lg="6"
              md="8"
              sm="10"
              cols="12"
              class="ml-auto me-auto"
              v-if="!loading && !error && !notAuthorizedError"
            >
              <b-form
                class="form"
                @submit="onCompleteAccount"
                :validated="false"
              >
                <b-card class="card-login">
                  <b-card-header class="card-header-text card-header-secondary">
                    <div class="card-text">
                      <h4 class="card-title">{{ $t('setup.header') }}</h4>
                    </div>
                  </b-card-header>
                  <b-card-body class="form-body pl-0 pl-sm-10 pr-0 pr-sm-10">
                    <b-row>
                      <b-col>
                        <p class="ml-sm-3">
                          {{
                            $t('setup.introText', {
                              name: defaultAttributes.given_name.value
                                ? ' ' + defaultAttributes.given_name.value
                                : ''
                            })
                          }}
                          <strong v-if="Object.keys(allAttributes).length">{{
                            $t('setup.introTextVolontary')
                          }}</strong>
                        </p>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="(attribute, name) in allAttributes"
                      v-bind:key="name"
                    >
                      <b-col v-if="!attribute.isHidden">
                        <TextInput
                          v-if="
                            attribute.type === 'string' &&
                            attributeNameShouldBeVisible(name)
                          "
                          :name="name"
                          :icon="attribute.icon"
                          :placeholder="attribute.placeholder"
                          v-model="attribute.value"
                          :required="requiredAttributes.includes(name)"
                        />
                        <SelectInput
                          v-if="
                            attribute.type === 'select' &&
                            attributeNameShouldBeVisible(name)
                          "
                          :name="name"
                          :icon="attribute.icon"
                          :info="attribute.info"
                          :options="attribute.options"
                          v-model="attribute.value"
                          :required="requiredAttributes.includes(name)"
                        />
                        <DateInput
                          v-if="
                            attribute.type === 'date' &&
                            attributeNameShouldBeVisible(name)
                          "
                          :name="name"
                          :icon="attribute.icon"
                          :placeholder="attribute.placeholder"
                          v-model="attribute.value"
                          :required="requiredAttributes.includes(name)"
                          :info="attribute.info"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <span class="bmd-form-group">
                          <b-input-group>
                            <b-input-group-prepend class="d-none d-sm-block">
                              <b-input-group-text>
                                <i class="bi bi-lock fs-3"></i>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              type="password"
                              :placeholder="$t('setup.placeholder.newPassword')"
                              v-model="newPassword"
                            ></b-form-input>
                          </b-input-group>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <span class="bmd-form-group">
                          <b-input-group>
                            <b-input-group-prepend class="d-none d-sm-block">
                              <b-input-group-text>
                                <i class="bi bi-lock fs-3"></i>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              type="password"
                              :placeholder="
                                $t('setup.placeholder.confirmPassword')
                              "
                              :state="validPasswords"
                              v-model="confirmPassword"
                            ></b-form-input>
                          </b-input-group>
                        </span>
                      </b-col>
                    </b-row>
                    <div v-if="completeError">
                      <p class="mt-3 ml-3 text-danger">{{ completeError }}</p>
                    </div>
                  </b-card-body>
                  <b-card-footer class="justify-content-center">
                    <b-button
                      variant="link"
                      size="lg"
                      type="submit"
                      class="btn-rose text-primary"
                      :disabled="submitButtonDisabled"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        v-if="loading"
                      ></span>
                      {{ $t('setup.button.submit') }}
                    </b-button>
                  </b-card-footer>
                </b-card>
              </b-form>
              <IEText />
            </b-col>
            <b-card class="ml-auto me-auto d-flex" v-if="notAuthorizedError">
              <p>{{ notAuthorizedError }}</p>
              <b-button
                variant="primary"
                v-if="organization && email && password"
                @click="
                  notAuthorizedError = null
                  authenticateWithTemporaryPassword()
                "
              >
                {{ $t('setup.button.tryAgain') }}
              </b-button>
              <p v-else>{{ $t('setup.error.clickLinkAgain') }}</p>
            </b-card>
            <b-card v-if="error">
              <b-card-body>
                <p class="d-flex align-content-center error-text mt-3">
                  <i class="bi bi-exclamation-octagon-fill fs-3 me-3"></i>
                  {{ error }}
                </p>
                <div v-if="errorDueToUnauthorized" class="error-text">
                  <a
                    class="btn btn-primary btn-round mt-3"
                    href="#"
                    @click="$router.push({ name: 'Overview' })"
                    >{{ $t('setup.login') }}</a
                  >
                </div>
              </b-card-body>
            </b-card>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Amplify from 'aws-amplify'
import IEText from './IEText'
import TextInput from './input/TextInput'
import SelectInput from './input/SelectInput'
import DateInput from './input/DateInput'
import uuid from 'uuid4'

export default {
  name: 'Setup',
  components: {
    TextInput,
    SelectInput,
    DateInput,
    IEText
  },
  data() {
    return {
      organization: '',
      email: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      loading: false,
      error: null,
      errorDueToUnauthorized: null,
      completeError: null,
      notAuthorizedError: null,
      requiredAttributes: [],
      allAttributes: {},
      customAttributes: {},
      defaultAttributes: {
        given_name: {
          icon: 'person-fill',
          type: 'string',
          placeholder: this.$t('setup.attributes.given_name.label'),
          value: null
        },
        family_name: {
          icon: 'person-fill',
          type: 'string',
          placeholder: this.$t('setup.attributes.family_name.label'),
          value: null
        },
        locale: {
          icon: 'globe',
          type: 'select',
          options: [
            { value: null, text: this.$t('setup.attributes.locale.label') },
            { value: 'en', text: this.$t('setup.attributes.locale.en') },
            { value: 'sv', text: this.$t('setup.attributes.locale.sv') },
            { value: 'fr', text: this.$t('setup.attributes.locale.fr') },
            { value: 'de', text: this.$t('setup.attributes.locale.de') }
          ],
          info: this.$t('setup.attributes.locale.info'),
          value: null
        },
        gender: {
          icon: 'accessibility',
          type: 'select',
          options: [
            { value: null, text: this.$t('setup.attributes.gender.label') },
            { value: 'male', text: this.$t('setup.attributes.gender.male') },
            {
              value: 'female',
              text: this.$t('setup.attributes.gender.female')
            },
            { value: 'other', text: this.$t('setup.attributes.gender.other') },
            {
              value: 'wontsay',
              text: this.$t('setup.attributes.gender.wontsay')
            }
          ],
          info: this.$t('setup.attributes.gender.info'),
          value: null
        },
        birthdate: {
          icon: 'calendar-date',
          type: 'date',
          placeholder: this.$t('setup.attributes.birthdate.label'),
          info: this.$t('setup.attributes.birthdate.info'),
          value: null
        },
        'custom:employed_since': {
          icon: 'briefcase-fill',
          type: 'date',
          placeholder: this.$t('setup.attributes.custom:employed_since.label'),
          info: this.$t('setup.attributes.custom:employed_since.info'),
          value: null
        }
      },
      cognitoUser: null,
      localesMap: {
        en: 'en-US',
        sv: 'sv-SE',
        nb: 'nb-NO',
        da: 'da-DK',
        fr: 'fr-FR',
        de: 'de-DE'
      }
    }
  },
  mounted() {
    if (
      this.$route.params.organization &&
      this.$route.params.username &&
      this.$route.params.temporarypassword
    ) {
      this.organization = this.$route.params.organization
      window.localStorage.organizationName = this.$route.params.organization
      this.email = this.$route.params.username
      this.password = this.$route.params.temporarypassword
      const l = this.$route.query.locale
      const setRandomPasswordAndSubmit = this.$route.query.quickstart
      if (
        l &&
        this.defaultAttributes.locale.options
          .map((loc) => loc.value)
          .includes(l)
      ) {
        this.defaultAttributes.locale.value = l
        this.setLocale(l)
      } else {
        this.reRenderAttributes()
      }
      return this.authenticateWithTemporaryPassword().then(() => {
        if (setRandomPasswordAndSubmit) {
          const randomPass = uuid().substring(0, 12)
          this.newPassword = randomPass
          this.confirmPassword = randomPass
          this.onCompleteAccount()
        }
      })
    } else {
      this.$router.redirect('/login')
    }
  },
  computed: {
    submitButtonDisabled() {
      return false
    },
    validPasswords() {
      if (this.newPassword.length === 0 || this.confirmPassword.length === 0) {
        return null // don't care about validation now
      } else {
        return (
          this.newPassword.length > 0 &&
          this.newPassword === this.confirmPassword &&
          this.newPassword.length > 3
        )
      }
    },
    organizationConfiguredAttributes() {
      if (
        window.localStorage.orgData &&
        JSON.parse(window.localStorage.orgData).attributes
      ) {
        return JSON.parse(window.localStorage.orgData).attributes
      } else {
        return []
      }
    },
    selectedLocale() {
      return this.defaultAttributes.locale.value
    }
  },
  watch: {
    selectedLocale(newLocale, oldLocale) {
      if (newLocale && newLocale !== oldLocale) {
        this.setLocale(newLocale)
        this.reRenderAttributes()
      }
    }
  },
  methods: {
    reRenderAttributes() {
      const attributes = {}
      Object.keys(this.defaultAttributes).forEach((key) => {
        const attribute = this.defaultAttributes[key]
        if (attribute.placeholder) {
          this.defaultAttributes[key].placeholder = this.$t(
            `setup.attributes.${key}.label`
          )
        }
        if (attribute.info) {
          this.defaultAttributes[key].info = this.$t(
            `setup.attributes.${key}.info`
          )
        }
        if (attribute.options) {
          attribute.options.forEach((option, i) => {
            if (option.value !== null) {
              this.defaultAttributes[key].options[i].text = this.$t(
                `setup.attributes.${key}.${option.value}`
              )
            } else {
              this.defaultAttributes[key].options[i].text = this.$t(
                `setup.attributes.${key}.label`
              )
            }
          })
        }
        attributes[key] = attribute
      })
      Object.keys(this.customAttributes)
        .filter((key) => this.customAttributes[key].messages)
        .forEach((key) => {
          const attribute = this.customAttributes[key]
          if (attribute.placeholder) {
            this.customAttributes[key].placeholder = this.$tr(
              attribute.messages,
              'label'
            )
          }
          if (attribute.info) {
            this.customAttributes[key].info = this.$tr(
              attribute.messages,
              'info'
            )
          }
          if (attribute.options) {
            attribute.options.forEach((option, i) => {
              if (option.value !== null) {
                this.customAttributes[key].options[i].text = this.$tr(
                  attribute.messages,
                  `options.${option.value}`
                )
              } else {
                this.customAttributes[key].options[i].text = this.$tr(
                  attribute.messages,
                  'label'
                )
              }
            })
          }
          attributes[key] = attribute
        })
      this.allAttributes = attributes
    },
    setLocale(locale) {
      window.localStorage.setItem('selectedLocale', locale)
      this.$i18n.locale = locale
    },
    attributeNameShouldBeVisible(attributeName) {
      return (
        this.organizationConfiguredAttributes.length === 0 || // no custom attributes found
        !this.organizationConfiguredAttributes
          .map((a) => a.name)
          .includes(attributeName) || // or attribute is not one of the custom attributes
        (this.organizationConfiguredAttributes
          .map((a) => a.name)
          .includes(attributeName) && // or it is, and it is enabled
          this.organizationConfiguredAttributes.filter(
            (a) => a.name === attributeName
          )[0].data.enabled)
      )
    },
    authenticateWithTemporaryPassword() {
      if (this.error) return
      this.errorDueToUnauthorized = false
      this.loading = true
      return Amplify.API.get(
        'PublicAPI',
        '/organizations/' + this.organization,
        {}
      )
        .then((orgResult) => {
          localStorage.organizationName = this.organization
          localStorage.orgData = JSON.stringify(orgResult)
          if (orgResult.attributes) {
            // disabled here, use Start instead
            // this.saveCustomAttributes(orgResult.attributes)
          }
          Amplify.configure({
            Auth: {
              identityPoolId: orgResult.identityPoolId,
              region: 'eu-west-1',
              userPoolId: orgResult.userPoolId,
              userPoolWebClientId: orgResult.userPoolWebClientId,
              mandatorySignIn: true,
              clientMetadata: {
                organizationName: this.organization,
                organizationFullName: orgResult.organizationFullName,
                locale: 'en' // TODO: Use some kind of default
              }
            }
          })
          this.initTheme(orgResult)
        })
        .then(() => Amplify.Auth.signIn(this.email, this.password))
        .then((user) => {
          this.cognitoUser = user
          this.loading = false
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.requiredAttributes = user.challengeParam.requiredAttributes
            if (user.challengeParam.userAttributes) {
              // disabled
              // this.updateAttributesFromUserAttributes(user.challengeParam.userAttributes)
            }
          } else {
            this.error =
              this.$t(`setup.error.unexpected`) + ': ' + user.challengeName
          }
        })
        .catch((err) => {
          this.loading = false
          // if (err.code === 'NotAuthorizedException') {
          this.errorDueToUnauthorized = true
          this.error = err ? this.$t(`setup.error.invalid`) : 'error:'
          // }
          // else {
          //   this.error = err ? this.$t(`setup.error.unexpected`) : 'error'
          // }
        })
    },
    initTheme(orgResult) {
      if (orgResult.variant === 'feelgood') {
        window.dispatchEvent(
          new CustomEvent('theme-changed', {
            detail: {
              theme: 'feelgood'
            }
          })
        )
      } else if (window.matchMedia) {
        const theme = window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
        window.dispatchEvent(
          new CustomEvent('theme-changed', {
            detail: {
              theme
            }
          })
        )
      }
    },
    onCompleteAccount(e) {
      if (e) {
        e.preventDefault()
      }
      this.completeError = null
      if (!this.newPassword && !this.confirmPassword) {
        this.completeError = this.$t('setup.error.noPassword')
      } else if (!this.newPassword || this.newPassword.length < 3) {
        this.completeError = this.$t('setup.error.badPassword')
      } else if (this.newPassword !== this.confirmPassword) {
        this.completeError = this.$t('setup.error.passwordsMustMatch')
      } else {
        this.loading = true
        let attributes = {}
        try {
          attributes = this.selectedAttributes()
        } catch (e) {
          this.loading = false
          this.completeError = e
        }
        Amplify.Auth.completeNewPassword(
          this.cognitoUser,
          this.newPassword,
          attributes
        )
          .then((user) => {
            this.$store.commit('setLoggedInUser', user)
            this.$store.commit('setHasToken', true)
            this.$router.push('/')
          })
          .catch((err) => {
            this.loading = false
            if (err.code === 'NotAuthorizedException') {
              this.notAuthorizedError = this.$t('setup.error.sessionError')
            } else {
              this.completeError = this.$t(`error.${err.code}`)
            }
          })
      }
    },
    updateAttributesFromUserAttributes(userAttributes) {
      Object.keys(userAttributes).forEach((userAttribute) => {
        if (Object.keys(this.defaultAttributes).includes(userAttribute)) {
          if (userAttribute === 'locale') {
            const foundLocaleKeys = Object.keys(this.localesMap).filter(
              (locale) =>
                this.localesMap[locale] === userAttributes[userAttribute]
            )
            if (foundLocaleKeys.length) {
              const foundLocale = foundLocaleKeys[0]
              this.defaultAttributes[userAttribute].value = foundLocale
            } else {
              // invalid locale found, fallback to 'en'
              this.defaultAttributes[userAttribute].value = 'en'
            }
          } else {
            this.defaultAttributes[userAttribute].value =
              userAttributes[userAttribute]
          }
        }
      })
      Object.keys(userAttributes).forEach((userAttribute) => {
        if (Object.keys(this.customAttributes).includes(userAttribute)) {
          this.customAttributes[userAttribute].value =
            userAttributes[userAttribute]
        }
      })
    },
    saveCustomAttributes(attributes) {
      attributes.forEach((attribute) => {
        const attr = {
          icon: attribute.data.icon ? attribute.data.icon : 'account_box',
          type: attribute.data.type,
          value: null,
          custom: true,
          messages: attribute.data.messages
        }
        if (attribute.data.messages) {
          if (attribute.data.messages.en.info) {
            attr.info = this.$tr(attribute.data.messages, 'info')
          }
          if (attribute.data.messages.en.label) {
            attr.placeholder = this.$tr(attribute.data.messages, 'label')
          }
          if (attribute.data.type === 'select') {
            attr.options = []
            attr.options.push({
              value: null,
              text: this.$tr(attribute.data.messages, 'label')
            })
            attr.options = attr.options.concat(
              Object.keys(attribute.data.messages.en.options).map(
                (optionName) => {
                  return {
                    value: optionName,
                    text: this.$tr(
                      attribute.data.messages,
                      `options.${optionName}`
                    )
                  }
                }
              )
            )
          }
          this.customAttributes[attribute.name] = attr
        } else {
          // no messages, this attribute is for toggling availability
          const isHidden = attribute.data.isHidden
          if (attribute.name.startsWith('custom')) {
            attr.isHidden = isHidden
          } else {
            if (this.defaultAttributes[attribute.name]) {
              this.defaultAttributes[attribute.name].isHidden = isHidden
            } else {
              // ignore attributes without messages that's not a default attribute
            }
          }
        }
      })
      this.reRenderAttributes()
    },
    // Collect all attributes that have values and reformat as needed
    selectedAttributes() {
      const attributes = {}
      Object.keys(this.defaultAttributes)
        .filter((key) => this.defaultAttributes[key].value)
        .forEach((key) => {
          const attribute = this.defaultAttributes[key]
          let value = attribute.value
          if (attribute.type === 'date') {
            if (attribute.value.toISOString) {
              value = attribute.value.toISOString().split('T')[0]
            }
          }
          if (key === 'locale') {
            value = this.localesMap[attribute.value]
          }
          attributes[key] = value
        })
      Object.keys(this.customAttributes)
        .filter((key) => this.customAttributes[key].value)
        .forEach((key) => {
          const attribute = this.customAttributes[key]
          let value = attribute.value
          if (attribute.type === 'date') {
            if (attribute.value.toISOString) {
              value = attribute.value.toISOString().split('T')[0]
            }
          }
          attributes[key] = value
        })
      return attributes
    }
  }
}
</script>

<style lang="scss" scoped>
.center-spinner {
  margin: 0 auto !important;
  float: none !important;
}

::-webkit-input-placeholder,
::-moz-placeholder {
  margin-left: 10px !important;
}

@media (max-width: 340px) {
  .date-input {
    width: 120px;
  }

  .form-body {
    margin-left: 0px;
    padding-left: 0px;
  }

  .vdp-datepicker__calendar {
    position: absolute;
    left: 0px;
  }
}

// .error-text {
//   font-size: 17px;
// }
</style>

<style>
.help-icon {
  cursor: help;
}
</style>
