<template>
  <div class="d-flex align-items-center">
    <i :class="`bi bi-${icon} fs-3 pt-1 me-3`"></i>

    <datepicker
      class="date-input flex-grow-1"
      v-bind:value="value"
      v-on:input="didSelectDate"
      :bootstrap-styling="true"
      input-class="datepicker-input"
      :placeholder="placeholder"
      initial-view="year"
      :required="required"
      :disabled-dates="disabledDates"
    ></datepicker>
    <i :id="name + '-help'" class="bi bi-question fs-2 text-info help-icon"></i>
    <b-tooltip
      :target="name + '-help'"
      placement="top"
      triggers="hover blur"
      delay="100"
    >
      {{ info }}
    </b-tooltip>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'DateInput',
  components: {
    Datepicker
  },
  data() {
    return {
      disabledDates: {
        from: new Date()
      }
    }
  },
  props: ['name', 'info', 'icon', 'placeholder', 'value', 'required'],
  methods: {
    didSelectDate(date) {
      this.$emit('input', date)
    }
  }
}
</script>

<style scoped>
.vdp-datepicker .form-control {
  background: var(--kt-form-select-bg);
}
</style>
